const dpx = window.devicePixelRatio || 1

export function optimImgSize (length) {
  const sizes = [320, 480, 640, 800, 1024, 1280, 1440, 1600, 1920, 2400, 3200, 4096, 5120]
  length = parseInt(length * dpx * 0.8) // less density optically ok ?
  // find optimal
  const optimal = sizes.find(sz => length <= sz)
  // use optimal otherwise largest src
  return optimal || sizes[sizes.length - 1]
}

export const variantId = graphqlId => graphqlId.split('/').pop()
