<template lang="pug">
  .product-guides.pb-45
    template(v-for='(guide, i) in guides')
      //- (size guide opens modal)
      template(v-if="$prismic.asText(guide.heading).toLowerCase() === 'size guide'")
        .relative
          button.absolute.overlay.block(@click="sizeGuide = true")
            span.sr-only View Size Guide
          details-dropdown.pointer-events-none(:summaryGray="grayModulus === i")
            h5(slot="summary") Size Guide
      //- (default: dropdowns)
      template(v-else)
        details-dropdown(:summaryGray="grayModulus === i")
          h5(slot="summary") {{ $prismic.asText(guide.heading) }}
          prismic-rich-text.children-mt-lh.pt-10.pb-15.px-17(:field="guide.text", :class="{'bg-gray-100': grayModulus === i}")

    //- size guide overlay
    //- * above header on lg, for shadow
    .fixed.z-20.lg_z-30.overlay.cursor-pointer(v-if="aro", :class="{'pointer-events-none': !sizeGuide}", @click="sizeGuide = false")
      //- panel
      .absolute.w-full.lg_w-1x3.top-0.right-0.h-full.transition-opacity.bg-white.cursor-default.overflow-y-scroll.scrollbars-hidden.pt-45.lg_pt-0.lg_shadow(ref="sizeGuide", :class="{'opacity-0 delay-400': !sizeGuide}", @click.stop)
        //- size guide content
        size-guide(:open="sizeGuide", @close="sizeGuide = false")
</template>

<script>
import { mapState } from 'vuex'
import DetailsDropdown from '@/components/DetailsDropdown'
import SizeGuide from '@/views/menu/SizeGuide'
export default {
  name: 'ProductGuides',
  props: ['grayModulus'],
  data () {
    return {
      sizeGuide: false,
      hdrClss: 'h-45 flex items-center cursor-pointer uppercase'
    }
  },
  computed: {
    ...mapState({
      aro: state => state.aro,
      guides: state => state.aro?.product_guides || []
    })
  },
  watch: {
    sizeGuide (open) {
      if (open) this.$refs.sizeGuide.scroll(0, 0)
    }
  },
  components: { SizeGuide, DetailsDropdown }
}
</script>

<style>
.product-guides{
  & ul, & ol{
    padding-left: 1.35em;
  }
  & ol{ list-style: decimal; }
  & ul{ list-style: disc }
}
</style>
