<template lang="pug">
  .related-products.relative
    //- (lazy query observer)
    observer(v-if="!docs", :threshold="0.001", @visible="get", style="padding-top:1800px;margin-top:-1800px")
    //- (results)
    template(v-if="docs && docs.length")
      h4.h-40.lg_h-60.flex.items-center.pb-3.lg_pb-4.text-25.lg_text-40.px-17.font-light RECOMMENDED
      ul.flex.flex-wrap.w-full
        li.w-1x2.md_w-1x4.lg_w-1x6(v-for="(doc, i) in docs.slice(0, 6)", :class="{'md_hidden lg_block': i > 3}")
          product-thumb.w-full(:doc="doc", :images="imgs(doc)", :price="doc.priceRange.minVariantPrice.amount", :hideSeason="true")
</template>

<script>
export default {
  name: 'RelatedProducts',
  props: ['product'],
  data () {
    return {
      docs: null
    }
  },
  methods: {
    async get () {
      /*
       * get products, first try by vendor, then by tag if < 6
       * TODO - narrow to same gender?
      */
      let query
      const docs = []

      // format results helper
      const results = resp => resp?.edges?.map(edge => edge.node) || []

      // 1. search for same vendor
      if (this.product.vendor?.length) {
        // build query
        query = `available_for_sale:true AND query=vendor:${this.product.vendor} AND -title:"${this.product.title}"`
        // query...
        const resp = await this.$store.dispatch('shop/getProducts', { query, first: 6 })
        // add
        this.docs = results(resp)
      }

      // 2. search by tag if <6 results...
      if (docs.length < 6 && this.product.tags?.length) {
        // build query
        query = this.product.tags.map(tag => `tag:"${tag}"`).join(' OR ') // tag:"Pants Long" OR ...
        query = `available_for_sale:true AND (${query}) AND -title:"${this.product.title}"`
        // query...
        const resp = await this.$store.dispatch('shop/getProducts', { query, first: 6 - docs.length })
        // add
        if (results(resp)?.length) {
          this.docs = this.docs.concat(results(resp))
        }
      }
    },
    imgs (doc) {
      return doc?.images?.edges?.map(e => e.node)
    }
  }
}
</script>

<style>
</style>
