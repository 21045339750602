<template lang="pug">
  modal.size-guide.text-black.font-mono.text-mono-12(v-on="$listeners", v-bind="$attrs")
    //- header row
    header.h-45.flex.items-center.justify-between.sticky.top-0.left-0.w-full.bg-white
      //- title
      h5.pl-15.uppercase.pt-3(tabindex="0") Size Guide
      //- close btn
      button.h-45.px-15.flex.items-center.justify-center.focus_bg-gray-100(@click="$emit('close')")
        span.icon-menu.icon-menu--close.transform.scale-90

    section.pb-17
      p.px-17 The size chart is a guide to help you find the right size for you. As many pieces are designed in a unique way, we suggest you also refer to the specific product lengths and measurements provided in the description.
      section.mt-2lh
        header.flex.justify-between.uppercase.mb-lh.px-17
          h6 Size Conversion
        table.w-full.text-center.-mt-8
          colgroup
            col.w-1x5
            col.w-1x5
            col.w-1x5
            col.w-1x5
            col.w-1x5
          thead
            tr
              td.text-left SIZE
              td XS
              td S
              td M
              td L
          tbody
            tr
              td(v-html="'EU'")
              td(v-html="'32&nbsp;&nbsp;34'")
              td(v-html="'36&nbsp;&nbsp;38'")
              td(v-html="'40&nbsp;&nbsp;42'")
              td(v-html="'44'")
            tr
              td(v-html="'UK'")
              td(v-html="'06&nbsp;&nbsp;08'")
              td(v-html="'10&nbsp;&nbsp;12'")
              td(v-html="'14&nbsp;&nbsp;16'")
              td(v-html="'18'")
            tr
              td(v-html="'FR/ES'")
              td(v-html="'34&nbsp;&nbsp;36'")
              td(v-html="'38&nbsp;&nbsp;40'")
              td(v-html="'42&nbsp;&nbsp;44'")
              td(v-html="'46'")
            tr
              td(v-html="'IT'")
              td(v-html="'38&nbsp;&nbsp;40'")
              td(v-html="'42&nbsp;&nbsp;44'")
              td(v-html="'46&nbsp;&nbsp;48'")
              td(v-html="'50'")

      section.mt-2lh
        header.flex.justify-between.uppercase.mb-lh.px-17
          h6 Body Measurements
          span CM
        table.w-full.text-center.-mt-8
          colgroup
            col.w-1x5
            col.w-1x5
            col.w-1x5
            col.w-1x5
            col.w-1x5
          thead
            tr
              td SIZE
              td XS
              td S
              td M
              td L
          tbody
            tr
              td(v-html="'CHEST'")
              td(v-html="'76&nbsp;&nbsp;80'")
              td(v-html="'84&nbsp;&nbsp;88'")
              td(v-html="'92&nbsp;&nbsp;96'")
              td(v-html="'100'")
            tr
              td(v-html="'WAIST'")
              td(v-html="'60&nbsp;&nbsp;64'")
              td(v-html="'68&nbsp;&nbsp;72'")
              td(v-html="'76&nbsp;&nbsp;80'")
              td(v-html="'85&nbsp;'")
            tr
              td(v-html="'HIPS'")
              td(v-html="'88&nbsp;&nbsp;88'")
              td(v-html="'92&nbsp;&nbsp;96'")
              td(v-html="'99&nbsp;&nbsp;102'")
              td(v-html="'105'")

      section.mt-2lh.children-mt-lh.px-17
        h6.uppercase HOW TO MEASURE
        p CHEST<br>Measure around the fullest part of your chest
        p WAIST<br>Measure at the narrowest part of your waistline
        p HIP<br>Measure the fullest part of your hips
        p MENSWEAR MODEL:<br>Chest: 106.7cm / Shoulder width 50cm / Waist 86.4cm / Hips: 86cm / Inside leg 91.5cm / Height 185.5cm
        p WOMENSWEAR MODEL:<br>Chest: 87cm / Shoulder width: 47cm / Waist :65 cm / Hips: 95cm / Inside leg: 74cm / Height : 177cm
</template>

<script>
export default {
  name: 'SizeGuide'
}
</script>

<style lang="postcss">
.size-guide{
  & table{
    /* @apply border-b; */
    & td {
      /* @apply border-r-0 */
    }
    & td:first-child{
      @apply text-left pl-17;
    }
    & td:last-child{
      @pply pr-17
    }
    & tbody tr:nth-child(odd) {
      @apply bg-gray-100
    }
    & td{
      @apply pt-14 pb-8;
    }
  }
}
</style>
