<template lang="pug">
  modal.product-image-viewer.fixed.z-40.overlay.overflow-y-scroll.scrollbars-hidden(:open="true")
    .lg_relative(@click="$emit('close')")
      //- thumb from cache
      img.absolute.overlay.object-cover.object-center.lg_static(:src="image.thumb", :alt="image.alt")
      //- hi-res
      img.absolute.overlay.z-10.object-cover.object-center(:src="srcOptim", :alt="image.alt")
</template>

<script>
import { resizeShopifyImg } from '@/components/RespImg'
export default {
  name: 'ProductImageViewer',
  props: ['image'],
  computed: {
    srcOptim () {
      const size = window.innerHeight > window.innerWidth * 1.1 ? [0, window.innerHeight] : [window.innerWidth]
      return resizeShopifyImg(this.image.src, size)
    }
  }
}
</script>

<style>
.product-image-viewer{
  cursor: url(~@/assets/icon-x.svg) 17 17, crosshair;
  &:active{
    cursor:default;
  }
}
</style>
