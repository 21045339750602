<template lang="pug">
  .product-description.font-mono.text-mono-12
    //- product description
    .children-mt-lh.mb-lh.px-17(v-html="descrip")
    //- product details
    details-dropdown(v-for="(key, i) in Object.keys(details)", :summaryGray="i % 2 === 0")
      h5(slot="summary") {{ key }}
      .children-mt-lh.pt-10.pb-15.px-17(v-html="details[key]", :class="{'bg-gray-100': i % 2 === 0}")
    //- global guides (sizing, help, ...)
    product-guides(:grayModulus="Object.keys(details).length % 2")
</template>

<script>
import ProductGuides from '@/components/product/ProductGuides'
import DetailsDropdown from '@/components/DetailsDropdown'
export default {
  name: 'ProductDescription',
  props: ['html'],
  data () {
    return {
      descrip: '',
      details: {}
    }
  },
  methods: {
    parse () {
      const html = document.createElement('div')
      html.innerHTML = this.html
      // pull out <ul> with child <ul> (nested list parents)
      const ululs = Array.from(html.children).filter(n => Array.from(n.children).find(c => c.nodeName === 'UL'))
      const details = {}
      ululs.forEach((node, i) => {
        let grp = i
        Array.from(node.children).forEach(child => {
          if (child.nodeName === 'LI') {
            // new group from LI
            grp = child.innerText
            details[grp] = ''
          } else {
            // content
            details[grp] += child.outerHTML
          }
        })
        node.remove()
      })
      this.details = details
      this.descrip = html.innerHTML
    }
  },
  created () {
    this.parse()
  },
  components: { ProductGuides, DetailsDropdown }
}
</script>

<style>
</style>
