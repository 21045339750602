<template lang="pug">
  //- md: 2col layout
  article.product.relative.min-h-screen.bg-white.pt-45.lg_pt-0
    template(v-if="doc")
      .relative.lg_static
        //- (breadcrumb - laptop)
        breadcrumb.hidden.lg_flex.sticky.z-20.lg_z-10.top-0.left-0.w-full.lg_-mb-60(:crumbs="breadcrumbs", :isWhite="true")
        //- mobile product title
        .lg_hidden.h-45.px-17.flex.items-center.inset-shadow-t.relative
          button.px-15.-ml-15.mt-px(@click="goBack")
            svg-chevron-left.block
          .min-w-0.flex-1.truncate.uppercase
            template(v-if="doc") {{ titleShort }}
            template(v-else-if="doc === null") Loading...
            template(v-else-if="doc === undefined") Not Found
        //- breadcrumb-sticky.z-20.lg_z-10.uppercase.lg_-mb-60(:back="true", @back="goBack", @click="goBack", :crumbs="[{title: `<span class='mr-15'>${doc.vendor}</span><span class='hidden lg_inline'>${variant.sku}</span>` }]")
          //- span.mr-15 {{ doc.vendor }}
          //- | {{ variant.sku }}

        .flex.flex-wrap.md_-mt-45
          //- text col
          .w-full.md_w-1x2.relative.flex.flex-col.font-sans.text-12
            .w-full.lg_w-33vw.ml-auto.pb-30
              .px-17
                //- heading
                header.pt-17.md_pt-175.mb-lh.flex.justify-between.text-14(role="banner")
                  .flex-1.flex.flex-col
                    h1.uppercase(v-html="title")
                    div(v-if="variant.sku") {{ variant.sku }}
                  div {{ price(variant.priceV2.amount) }}
                //- text
                variant-options-list.mb-lh.leading-1p4(:variant="variant", v-if="variant")
              //- description
              product-description(:html="doc.descriptionHtml")

          //- BUY BUTTON
          //- (sticky top and bottom)
          .sticky.z-10.w-full.top-45.lg_top-0.bottom-0.h-45.lg_-mt-45.pointer-events-none(v-if="variant")
            button.block.w-full.lg_w-1x3.ml-auto.bg-black.text-white.pointer-events-auto(@click="addToBag", :disabled="!variant.availableForSale || quantityLimitReached")
              btn
                template(v-if="!variant.availableForSale") Sold
                template(v-else-if="quantityLimitReached") In Basket
                template(v-else) Add to Basket

          //- images...
          //- (first ordered before text column)
          figure.product__image.w-full.md_w-1x2(v-for="(image, i) in images", :class="{'order-first': !i}")
            //- img.block.w-full(:src="image.transformedSrc", :alt="image.altText")
            .relative.pb-ar-2x3.bg-gray-100
              resp-img(:bg="true", :image="{url: image.originalSrc, alt: image.altText || `${doc.title} - Aro Archive}`}", @click="$event => openViewer($event, image)")

      //- related products
      footer.w-full
        related-products(:product="doc")
      //- image overlay
      product-image-viewer(v-if="viewer", :image="viewer", @close="viewer = null")
</template>

<script>
import store from '@/store'
import { mapState, mapGetters } from 'vuex'
import { variantId as vId } from '@/utils'
import ProductDescription from './ProductDescription'
import RelatedProducts from '@/components/product/RelatedProducts'
import VariantOptionsList from '@/components/product/VariantOptionsList'
import ProductImageViewer from '@/components/product/ProductImageViewer'
import kb from 'lodash/kebabCase'
let doc, prevRt
export default {
  name: 'Product',
  data () {
    return {
      doc,
      variant: null,
      viewer: null,
      winW: window.innerWidth,
      from: null
    }
  },
  computed: {
    ...mapState({ checkout: state => state.shop.checkout }),
    ...mapGetters({ price: 'shop/price' }),
    title () {
      // return this.doc?.title.replace(this.doc.vendor.toUpperCase() + ' ', (m) => m.replace(' ', '<br>')).replace(/[A-Z]{2}\d*.?$/, '')
      // start with vendor
      let title = this.doc?.vendor?.length ? this.doc.vendor + '<br>' : ''
      // add title (but remove season code)
      title += this.doc?.title.split(this.doc.vendor)[1]?.replace(/[A-Z]{2}\d*.?$/, '')
      return title
    },
    titleShort () {
      return this.title.replaceAll('<br>', ' ')
    },
    images () {
      return this.doc?.images.edges.map(e => e.node) || []
    },
    quantityLimitReached () {
      return this.checkout?.lineItems.find(itm => itm.variant?.id === this.variant?.id)?.quantity === this.variant?.quantityAvailable
    },
    breadcrumbs () {
      const crumbs = []
      // add collection ?
      let collection = this.$route.params.collection
      if (collection && collection !== 'all') {
        crumbs.push({
          title: collection.replaceAll('-', ' '),
          path: `/collections/${collection}`
        })
      }
      // add type filter ?
      if (this.from?.query.type) {
        collection = collection || 'all'
        const firstType = this.from.query.type.split(',')[0]
        crumbs.push({
          title: firstType.replaceAll('-', ' '),
          path: `/collections/${collection}?type=${firstType}`
        })
      }
      // add product
      if (this.doc) {
        collection = collection || 'all'
        crumbs.push({
          title: this.doc.vendor,
          path: `/collections/${collection}?designer=${kb(this.doc.vendor)}`
        })
      } else {
        crumbs.push({ title: 'Loading...', path: this.$route.path })
      }
      return crumbs
    }
  },
  methods: {
    getDefaultVariant (product) {
      const variants = product?.variants?.edges?.map(e => e.node) || []
      const rtVariant = variants.find(vrnt => vId(vrnt.id) === this.$route.query.variant)
      return rtVariant || variants.find(v => v.availableForSale) || variants[0]
    },
    addToBag () {
      this.$store.dispatch('shop/addItemToCheckout', { variantId: this.variant.id, quantity: 1, customAttributes: [{ key: 'vendor', value: this.doc.vendor }] })
    },
    goBack () {
      return prevRt?.name ? this.$router.go(-1) : this.$router.push('/')
      // return !prevRt || !['aro-home', 'aro-collection'].includes(prevRt.name) ? this.$router.push('/') : this.$router.go(-1)
    },
    openViewer (thumb, image) {
      this.viewer = { thumb: thumb, src: image.originalSrc, alt: image.altText }
    }
  },

  // lifecycle
  async beforeRouteEnter (to, from, next) {
    prevRt = from
    await getProductFromPrismic(to, from)
    next()
  },
  async beforeRouteUpdate (to, from, next) {
    this.doc = await getProductFromPrismic(...arguments)
    next()
  },
  async created () {
    this.from = prevRt
    this.doc = doc || await getProductFromPrismic(this.$route) // for dev hot-reload...
    this.variant = this.getDefaultVariant(this.doc)
  },

  metaInfo () {
    const stripHtml = str => str.replace(/<\/?[^>]+(>|$)/g, '')
    return this.doc && {
      title: this.doc.title,
      meta: this.$store.getters.meta({
        title: this.doc.title,
        descrip: stripHtml(this.doc.descriptionHtml).split('\n')[0],
        img: this.images[0]?.meta
      }),
      // canonical link since products also visible via /collections/... path
      link: [
        { rel: 'canonical', href: window.location.origin + /products/ + this.doc.handle }
      ]
    }
  },
  components: { ProductImageViewer, ProductDescription, RelatedProducts, VariantOptionsList }
}

const getProductFromPrismic = async (to) => {
  try {
    // fetch...
    doc = await store.dispatch('shop/getProduct', { handle: to.params.product })
    return doc
  } catch (e) {
    console.error(e)
  }
}
</script>

<style lang="postcss">
.product__image{
  cursor: url(~@/assets/icon-plus-large.svg) 37 37, crosshair;
  &:active{
    cursor: url(~@/assets/icon-x.svg) 17 17, crosshair;
  }
}
</style>
